export const seo = {
  url: 'intermodal',
  title: {
    en: 'Intermodal Transport | Omida Transport of the future',
    es: 'Transporte intermodal | Transporte del futuro de Omida',
    ro: 'Transport intermodal | Omida Transportul viitorului',
  },
  desc: {
    en: 'Long-term contract, warranty, cargo safety, time optimization and up to 50% savings. Intermodal transport with an experienced TFL company.',
    es: 'Contrato a largo plazo, garantía, seguridad de la carga, optimización de tiempos y hasta un 50% de ahorro. Transporte intermodal con una empresa TFL experimentada',
    ro: 'Contract pe termen lung, garanție, siguranță încărcăturii, optimizare a timpului și economii de până la 50%. Transport intermodal cu o companie TFL cu experienta.',
    
  },
  keywords: [
    'intermodal',
    'transport',
    'long-term contract',
    'optimization',
    'tfl',
  ],
}

export const intro = {
  title: {
    en: 'Intermodal',
    es: 'Intermodal',
    ro: 'Intermodal',
  },
  desc: {
    en: 'Intermodal Transport is a solution that allows you to reduce transport costs on specific routes and directions. It is a transport in which we use several forms of transport in one transport unit along the entire route.',
    es: 'El Transporte Intermodal es una solución que le permite reducir los costos de transporte en rutas y direcciones específicas. Es un transporte en el que utilizamos varios medios de transporte en una misma unidad de transporte a lo largo de todo el recorrido.',
    ro: 'Transportul Intermodal este o soluție care vă permite să reduceți costurile de transport pe anumite rute și direcții. Este un transport în care folosim mai multe forme de transport într-o singură unitate de transport de-a lungul întregului traseu.',
  },
  buttonSecond: {
    text: {
      en: 'Quick Start',
      es: 'Inicio rápido',
      ro: 'Pornire rapidă',
    },
    link: '/intermodal/#form',
  },
  button: {
    text: {
      en: 'Read More',
      es: 'Lee mas',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    en: 'Intermodal transport - take advantage of the transport possibilities of the future',
    es: 'Transporte intermodal: aproveche las posibilidades de transporte del futuro',
    ro: 'Transport intermodal - profitați de posibilitățile de transport ale viitorului',
  },
  texts: [
    {
      en: `<span>In intermodal transport it is possible to prepare <strong>a long-term contract with a price guarantee</strong>. This solution is dedicated to companies that want to obtain a guarantee of stability of transport costs throughout the year.</span>`,
      es: '<span> En el transporte intermodal es posible preparar <strong> un contrato a largo plazo con garantía de precio </strong>. Esta solución está dedicada a las empresas que quieren obtener una garantía de estabilidad de los costes de transporte durante todo el año.</span>',
      ro: '<span>În transportul intermodal este posibil să se întocmească <strong>un contract pe termen lung cu o garanție de preț</strong>. Această soluție este dedicată companiilor care doresc să obțină o garanție de stabilitate a costurilor de transport pe tot parcursul anului.</span>',
    },
    {
      en: `<span>The advantages of intermodal transport are <strong>cargo safety and transport time optimization</strong>. The goods in motion are not exposed to unfavorable external factors.</span>`,
      es: `<span> Las ventajas del transporte intermodal son <strong> la seguridad de la carga y la optimización del tiempo de transporte </strong>. Las mercancías en movimiento no están expuestas a factores externos desfavorables.</span>`,
      ro: `<span>Avantajele transportului intermodal sunt <strong>siguranța mărfurilor și optimizarea timpului de transport</strong>. Mărfurile aflate în mișcare nu sunt expuse unor factori externi nefavorabili.</span>`,
    },
    {
      en: `<span>By combining various forms of transport, we enable the optimization of current costs. If the cargo can be transported a little longer, our solution can bring <strong>even 50% savings</strong> with cyclical deliveries.</span>`,
      es: `<span> Al combinar varias formas de transporte, permitimos la optimización de los costos actuales. Si la carga se puede transportar un poco más, nuestra solución puede generar <strong> incluso un 50 % de ahorro </strong> con entregas cíclicas. </span>`,
      ro: `<span>Combinând diverse forme de transport, permitem optimizarea costurilor curente. Dacă încărcătura poate fi transportată puțin mai mult, soluția noastră poate aduce <strong>chiar economii de 50%</strong> cu livrări ciclice.</span>`,
    },
  ],

  features: [
    {
      icon: 'eco',
      subtitle: {
        en: '',
        es: '',
        ro: '',
      },
      title: {
        en: 'Ecology',
        es: 'Ecología',
        ro: 'Ecologie',
      },
      desc: {
        en: 'Intermodal transport allows to reduce the emission of harmful substances for our health and for our planet by up to 60%',
        es: 'El transporte intermodal permite reducir hasta en un 60% la emisión de sustancias nocivas para nuestra salud y para nuestro planeta.',
        ro: 'Transportul intermodal permite reducerea emisiilor de substanțe nocive pentru sănătatea noastră și pentru planeta noastră cu până la 60%',
      },
    },
    {
      icon: 'shield',
      subtitle: {
        en: '',
        es: '',
        ro: '',
      },
      title: {
        en: 'Safety',
        es: 'Seguridad',
        ro: 'Siguranță',
      },
      desc: {
        en: 'Intermodal transport minimizes the possibility of undesirable events and random situations - damage or loss of goods, accidents and theft.',
        es: 'El transporte intermodal minimiza la posibilidad de eventos indeseables y situaciones aleatorias: daño o pérdida de bienes, accidentes y robo.',
        ro: 'Transportul intermodal minimizează posibilitatea apariției unor evenimente nedorite și situații aleatorii - deteriorarea sau pierderea mărfurilor, accidente și furt.',
      },
    },
    // {
    //   icon: 'time',
    //   subtitle: {
    //     en: '',
    //     es: '',
    //   },
    //   title: {
    //     en: 'Confidence',
    //     es: 'Confianza',
    //   },
    //   desc: {
    //     en: 'Our solution guarantees the certainty of transport even in the most intense and difficult period for the TFL industry.',
    //     es: 'Nuestra solución garantiza la certeza del transporte incluso en el período más intenso y difícil para la industria TFL.',
    //   },
    // },
    {
      icon: 'dollar',
      subtitle: {
        en: '',
        es: '',
        ro: '',
      },
      title: {
        en: 'Optimization',
        es: 'Mejoramiento',
        ro: 'Optimizare',
      },
      desc: {
        en: 'Intermodal transport, thanks to timely contracts, guarantees a constant price for the transport of Goods - which often reduces costs by up to 50% compared to road transport.',
        es: 'El transporte intermodal, gracias a los contratos oportunos, garantiza un precio constante para el transporte de Mercancías, lo que a menudo reduce los costos hasta en un 50% en comparación con el transporte por carretera.',
        ro: 'Transportul intermodal, gratie contractelor la timp, garantează un preț constant pentru transportul de Mărfuri – care deseori reduce costurile cu până la 50% față de transportul rutier.',
      },
    },
  ],
}

export const video = {
  title: {
    en: 'Get to know the details of the new Omida VLS product',
    es: 'Conozca los detalles del nuevo producto de Logística de Omida',
    ro: 'Cunoașteți detaliile noului produs Omida VLS',
  },
  desc: {
    en: 'In the attached material, Mateusz Kokosza talks about how we handle services in Intermodal Transport.',
    es: 'En el material adjunto, Mateusz Kokosza habla sobre cómo manejamos los servicios en el Transporte Intermodal.',
    ro: 'În materialul atașat, Mateusz Kokosza vorbește despre modul în care gestionăm serviciile în Transportul Intermodal.',
  },
  url: 'https://www.youtube.com/watch?v=Y1sFnW3CYMg',
}

export const stages = {
  id: 'stages',
  title: {
    en: 'Processes - Intermodal Transport',
    es: 'Procesos - Transporte Intermodal',
    ro: 'Procese - Transport Intermodal',
  },
  desc: {
    en: 'Learn about the Omida Intermodal service processes',
    es: 'Conozca los procesos del servicio Intermodal Omida',
    ro: 'Aflați despre procesele de servicii Omida Intermodal',
  },
  stage: {
    en: 'Stage',
    es: 'Escenario',
    ro: 'Etapă',
  },
  stages: [
    {
      number: '01',
      title: {
        en: 'Reception',
        es: 'Recepción',
        ro: 'Recepție',
      },
      body: {
        en: `<ul><li>At this stage, we collect our client's goods and transport them by tractor and semi-trailer to the railway terminal.</li><li> We offer goods collection as part of intermodal transport within a radius of 300 km from the railway terminals.</li></ul>`,
        es: `<ul> <li> En esta etapa, recogemos la mercancía de nuestro cliente y la transportamos en tractor y semirremolque hasta la terminal ferroviaria.</li> <li> Ofrecemos la recogida de mercancías como parte del transporte intermodal en un radio de 300 km de las terminales ferroviarias. </li> </ul>`,
        ro: `<ul><li>În această etapă, colectăm bunurile clientului nostru și le transportăm cu tractor și semiremorcă la terminalul feroviar.</li><li> Oferim ridicarea mărfurilor ca parte a transportului intermodal pe o rază de 300 km de terminalele feroviare.</li></ul>`,
      },
    },
    {
      number: '02',
      title: {
        en: 'Delivery to the Terminal',
        es: 'Entrega a la Terminal',
        ro: 'Livrare la terminal',
      },
      body: {
        en: '<ul><li>After our driver takes over the goods, the loaded trailer is delivered to the terminal.</li><li> Drivers driving in wheels on the routes served, in an intermodal product, obtain the best delivery times.</li> </ul>',
        es: '<ul> <li>Después de que nuestro conductor se haga cargo de la mercancía, el remolque cargado se entrega en la terminal.</li> <li>Los conductores que conducen sobre ruedas en las rutas servidas, en un producto intermodal, obtienen los mejores tiempos de entrega. </li> </ul>',
        ro: '<ul><li>După ce șoferul nostru preia marfa, remorca încărcată este predată la terminal.</li><li> Șoferii care circulă pe roți pe rutele deservite, într-un produs intermodal, obțin cele mai bune timpi de livrare. </li> </ul>',
      },
    },
    {
      number: '03',
      title: {
        en: 'Lashing the Cargo',
        es: 'Amarrar la carga',
        ro: 'Fixarea încărcăturii',
      },
      body: {
        en: `
        <ul>
          <li>After reaching the place of loading, the trailer is transferred to a dedicated wagon.</li>
          <li>Reach stackers and cranes are used for this purpose.</li>
          <li>When the semi-trailer is loaded onto the rolling stock, the tractor driver can make subsequent deliveries and pick-ups within a radius of 300 km from the railway terminal.</li>
      </ul>
        `,
        es: `
        <ul>
           <li> Después de llegar al lugar de carga, el remolque se transfiere a un vagón exclusivo. </li>
           <li> Los apiladores de alcance y las grúas se utilizan para este fin. </li>
           <li> Cuando el semirremolque se carga en el material rodante, el conductor del tractor puede realizar entregas y recogidas posteriores en un radio de 300 km desde la terminal ferroviaria. </li>
         </ul>
         `,
        ro: `
        <ul>
            <li>După ce ajunge la locul de încărcare, remorca este transferată într-un vagon dedicat.</li>
            <li>În acest scop sunt utilizate stivuitoare și macarale.</li>
            <li>Când semiremorca este încărcată pe materialul rulant, tractorul poate efectua livrări și ridicări ulterioare pe o rază de 300 km de la terminalul feroviar.</li>
        </ul>
         `,
      },
    },
    {
      number: '04',
      title: {
        en: 'Carriage',
        es: 'Carro',
        ro: 'Transport',
      },
      body: {
        en: `
        <ul>
        <li>At a certain time, almost every day, the entire train depot starts from the terminal to transport the goods to their final destination.</li>
        <li>The key advantage in rail transport is the continuous movement of our goods.</li>
      </ul>
        `,
        es: `
        <ul>
           <li> En un momento determinado, casi todos los días, todo el depósito de trenes parte de la terminal para transportar las mercancías a su destino final. </li>
           <li> La principal ventaja del transporte ferroviario es el movimiento continuo de nuestras mercancías. </li>
         </ul>
        `,
        ro: `
        <ul>
          <li> Într-un moment determinat, aproape toate zilele, tot depozitul de trenuri parte de la terminal pentru transportul mărfurilor la destinația finală. </li>
          <li> Principalul avantaj al transportului feroviar este circulația continuă a mărfurilor noastre. </li>
        </ul>
        `,
      },
    },
    {
      number: '05',
      title: {
        en: 'Unloading',
        es: 'Descarga',
        ro: 'Descărcare',
      },
      body: {
        en: `
        <ul>
          <li>The cargo goes to the destination terminal, and then the trailer is unloaded from the wagon and awaits collection by our driver.</li>
          <li>After connecting the trailer to the tractor, the load is delivered to the pickup point in accordance with the guidelines agreed with our client.</li>
      </ul>
        `,
        es: `
        <ul>
           <li> La carga va a la terminal de destino, y luego el remolque se descarga del vagón y espera a que lo recoja nuestro conductor. </li>
           <li> Después de conectar el remolque al tractor, la carga se entrega en el punto de recogida de acuerdo con las pautas acordadas con nuestro cliente. </li>
         </ul>
        `,
        ro: `
        <ul>
          <li> Marfa merge la terminalul de destinație, iar apoi remorca este descărcată din vagon și pregătită pentru a fi preluată de șoferul nostru. </li>
          <li> După ce remorca este conectată la tractor, încărcătura este livrată la punctul de preluare în conformitate cu liniile directoare convenite cu clientul nostru. </li>
        </ul>
        `,
      },
    },
  ],
}

export const products = {
  id: 'products',
  title: {
    en: 'Choose Intermodal Transport and gain an advantage in Business',
    es: 'Elige Transporte Intermodal y obtén ventaja en los Negocios',
    ro: 'Alegeți Transportul Intermodal și câștigă un avantaj în Afaceri',
  },
  desc: {
    en: 'One unit, many possibilities - see how we do it!',
    es: 'Una unidad, muchas posibilidades: ¡vea cómo lo hacemos!',
    ro: 'O unitate, multe posibilități - aflați cum o facem!',
  },
  products: [
    {
      image: 'road',
      name: {
        en: 'Road Transport',
        es: 'Transporte por carretera',
        ro: 'Transport rutier',
      },
      description: {
        en: 'Transport of goods moving on land roads by means of motor vehicles. Transport services using this mode of transport are provided by road carriers.',
        es: 'Transporte de mercancías que se desplazan por vías terrestres mediante vehículos a motor. Los servicios de transporte que utilizan este modo de transporte son proporcionados por transportistas por carretera.',
        ro: 'Transport de mărfuri care circulă pe drumuri terestre cu autovehicule. Serviciile de transport care folosesc acest mod de transport sunt asigurate de transportatorii rutieri.',
      },
      button: {
        en: 'Try Omida Intermodal',
        es: 'Elija Omida Intermodal',
        ro: 'Încercați Omida Intermodal',
      },
      link: '/intermodaly/#form',
    },
    {
      image: 'rail',
      name: {
        en: 'Rail Transport',
        es: 'Transporte Ferroviario',
        ro: 'Transport pe cale ferată',
      },
      description: {
        en: 'Inland transport that moves goods by rail vehicles along a given rail network. Railway is a form of rigidly track transport, i.e. its vehicles can only move on a track that is characteristic for a given technology.',
        es: 'Transporte interior que mueve mercancías mediante vehículos ferroviarios a lo largo de una red ferroviaria determinada. El ferrocarril es una forma de transporte de vía rígida, es decir, sus vehículos solo pueden moverse en una vía que es característica de una tecnología determinada.',
        ro: 'Transport interior care deplasează mărfuri cu vehicule feroviare de-a lungul unei anumite rețele feroviare. Calea ferată este o formă de transport pe șine rigidă, adică vehiculele sale se pot deplasa numai pe o cale care este caracteristică pentru o anumită tehnologie.',
      },
      button: {
        en: 'Try Omida Intermodal',
        es: 'Elija Omida Intermodal',
        ro: 'Încercați Omida Intermodal',
      },
      link: '/intermodaly/#form',
    },
    {
      image: 'meritime',
      name: {
        en: 'Maritime Transport',
        es: 'Transporte Maritimo',
        ro: 'Transport maritim',
      },
      description: {
        en: 'Water transport including specialized ships, incl. container ships, tankers and bulk carriers by sea. Sea Transport is also known as Sea Shipping.',
        es: 'Transport wodny obejmujący przewóz wyspecjalizowanymi statkami m.in. kontenerowcami, tankowcami i masowcami ładunków drogą morską. Transport morski jest również znany pod pojęciem Żeglugi morskiej.',
        ro: 'Transport pe apă, inclusiv nave specializate, incl. nave-containere, cisterne și vrachiere pe mare. Transportul maritim este cunoscut și sub numele de transport maritim.',
      },
      button: {
        en: 'Try Omida Intermodal',
        es: 'Elija Omida Intermodal',
        ro: 'Încercați Omida Intermodal',
      },
      link: '/intermodaly/#form',
    },
  ],
}

export const industries = {
  title: {
    en: 'Which industries use Intermodal Transport?',
    es: '¿Qué industrias utilizan el Transporte Intermodal?',
    ro: 'Ce industrii folosesc transportul intermodal?',
  },
  desc: {
    en: 'See examples of the use of intermodal in maintaining the supply chain. We dedicate intermodal transport to companies operating for the protection of the environment, regularly sending large amounts of goods to their contractors.',
    es: 'Vea ejemplos del uso de intermodal en el mantenimiento de la cadena de suministro. Dedicamos el transporte intermodal a empresas que operan para la protección del medio ambiente, enviando regularmente grandes cantidades de mercancías a sus contratistas.',
    ro: 'Vezi exemple de utilizare a intermodalului în menținerea lanțului de aprovizionare. Dedicăm transportul intermodal companiilor care operează pentru protecția mediului, trimițând în mod regulat cantități mari de mărfuri contractorilor lor.',
  },
  include: [
    'Motoryzacja',
    'Papiernicza',
    'Chemiczna',
    'Modowa',
    'Beauty',
    'Elektroniczna',
    'AGD',
    'Spożywcza',
    'Budownicza',
  ],
}

export const form = {
  id: 'form',
  title: {
    en: 'Check what savings you can generate by choosing Intermodal Transport',
    es: 'Comprueba qué ahorro puedes generar eligiendo Transporte Intermodal',
    ro: 'Verificați ce economii poți genera alegând Transport Intermodal',
  },
  desc: {
    en: '',
    es: '',
    ro: '',
  },
  fields: {
    email: {
      label: { en: 'Email', es: 'Email', ro: 'Email' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { en: 'Contact person', es: 'Persona de contacto', ro: 'Persoana de contact' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    message: {
      label: { en: 'Message', es: 'Mensaje', ro: 'Mesaj' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      en: 'I agree to receive by e-mail commercial information coming from Omida VLS Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
      es: 'Acepto recibir información comercial por medios electrónicos en el sentido de la Ley de 18 de julio de 2002 sobre prestación de servicios electrónicos con contenidos de Omida VLS Sp. z o.o.',
      ro: 'Sunt de acord să primesc prin e-mail informații comerciale provenite de la Omida VLS Sp. z o.o. în sensul Legii din 18 iulie 2002 privind serviciile electronice',
    },
    {
      en: 'I hereby consent to the use by Omida VLS Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
      es: 'Consiento el uso por parte de Omida VLS Sp. z o.o. equipos terminales de telecomunicaciones y sistemas automáticos de llamada para presentar información comercial en el sentido de lo dispuesto en la Ley de 16 de julio de 2014 - Ley de Telecomunicaciones',
      ro: 'Sunt de acord cu utilizarea de către Omida VLS Sp. z o.o. a echipamentelor terminale de telecomunicații și a sistemelor automate de apelare pentru a prezenta informații comerciale în sensul Legii din 16 iulie 2014. -Legea telecomunicațiilor',
    },
  ],
  administrating: {
    en: 'The administrator of your personal data will be Omida VLS Sp. z o.o.(hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
    es: 'El administrador de sus datos personales será Omida VLS Sp. z o.o. (en adelante: Omida). Puede ponerse en contacto con nosotros de la siguiente manera: por carta a la siguiente dirección: Aleja Grunwaldzka 472C, 80-309 Gdańsk, por correo electrónico:',
    ro: 'Administratorul datelor dumneavoastră personale va fi Omida VLS Sp. z o.o. (în continuare: Omida). Ne puteți contacta după cum urmează: prin scrisoare către: Aleja Grunwaldzka 472C, 80-309 Gdańsk, prin e-mail:',
  },
  administratingSecond: {
    en: ' or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
    es: ' o por teléfono: +48 58 741 88 14. Trataremos los datos personales para preparar ofertas de productos y servicios de Omida. Puede encontrar información detallada sobre el procesamiento de datos personales',
    ro: ' sau la telefon: +48 58 741 88 14. Datele personale vor fi prelucrate în vederea pregătirii ofertelor pentru produsele și serviciile Omida. Detalii despre prelucrarea datelor dumneavoastră cu caracter personal sunt disponibile',
  },
  administratingLinkText: {
    en: 'here',
    es: 'aquí',
    ro: 'aici',
  },
  dictonary: {
    success: {
      title: { en: 'Thank you!', es: '¡Gracias!', ro: '¡Gracias!' },
      text: {
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
        es: 'Tu mensaje ha sido enviado. Le contestaremos lo antes posible. También enviamos una confirmación del mensaje a la dirección proporcionada',
        ro: 'Mesajul tau a fost trimis. Vă vom răspunde cât mai curând posibil. De asemenea, v-am trimis confirmarea la adresa de e-mail furnizată',
      },
    },
    failure: {
      title: { en: 'Failure!', es: '¡Falla!', ro: 'Eșec!' },
      text: {
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
        es: 'Parece que su mensaje no ha sido enviado. Contáctenos por correo electrónico o por teléfono',
        ro: 'Se pare că mesajul tău nu a fost trimis. Contactați-ne prin e-mail sau telefonic',
      },
    },
    send: {
      en: 'Send',
      es: 'Enviar',
      ro: 'Trimiteți',
    },
    fill: {
      en: 'Fill up the form',
      es: 'Rellene el formulario',
      ro: 'Completați formularul',
    },
    fillagreements: {
      en: 'Agree to the terms',
      es: 'Seleccione el consentimiento',
      ro: 'De acord cu termenii',
    },
  },
}

export const buttonBack = {
  text: {
    en: 'Ask for an offer',
    es: 'Pide una oferta',
    ro: 'Cereți o ofertă',
  },
  link: '/intermodal/#form',
} 
